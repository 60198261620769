@tailwind base;
@tailwind components;
@tailwind utilities;

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-top: 2px solid #043fb5;
  z-index: 2000;
}

.container {
  height: 100px;
  background-image: linear-gradient(
    to bottom,
    rgb(9, 9, 108),
    rgb(48, 78, 197)
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  box-shadow: 0 0 0.5rem saddlebrown;
}
